var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "photo-book-cus-box",
    staticStyle: {
      "background-color": "#E8E4E4"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Diy"
    }
  }), _c('br'), _vm._l(_vm.pictureList, function (item, index) {
    return [_c('div', {
      key: index + 100,
      class: {
        'odd': index % 2 === 0,
        'even': index % 2 === 1,
        'selected': _vm.selectedIndex == index
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      on: {
        "click": function ($event) {
          return _vm.selectPicture(index);
        }
      }
    }, [_c('div', {
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index + '-area'
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('van-image', {
      style: {
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "src": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : '0',
        "width": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0',
        "height": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0',
        "display": "inline-block"
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function () {
          return [_c('van-loading', {
            staticStyle: {
              "color": "red"
            },
            attrs: {
              "type": "spinner",
              "size": "50"
            }
          })];
        },
        proxy: true
      }], null, true)
    }) : _vm._e(), (_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('div', {
      directives: [{
        name: "touch",
        rawName: "v-touch:pan",
        value: _vm.doAction,
        expression: "doAction",
        arg: "pan"
      }, {
        name: "touch",
        rawName: "v-touch:pinch",
        value: _vm.doAction,
        expression: "doAction",
        arg: "pinch"
      }],
      staticClass: "custom-user-image-option-box",
      class: 'custom-user-image-option-box-' + index,
      style: {
        width: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0px',
        height: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0px',
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index
      }
    }) : _vm._e(), (_vm.pictureInfoList[index].userImgInfo[0].photoInfo.url ? false : true) ? _c('div', {
      class: {
        'odd-update': index % 2 === 0,
        'even-update': index % 2 === 1
      },
      staticStyle: {
        "position": "absolute",
        "bottom": "0px",
        "width": "19.24vw",
        "height": "19.24vw",
        "background-color": "#BBBBBB",
        "opacity": "0.7",
        "z-index": "11"
      }
    }, [_c('van-uploader', {
      staticStyle: {
        "width": "19.24vw",
        "height": "19.24vw",
        "display": "flex",
        "z-index": "11",
        "justify-content": "center",
        "align-items": "center",
        "font-weight": "bold"
      },
      attrs: {
        "max-size": 26214400,
        "accept": "image/*",
        "after-read": _vm.createAfterReadHandler(index)
      },
      on: {
        "oversize": _vm.onOversize
      }
    }, [_c('van-icon', {
      staticStyle: {
        "margin-left": "5vw"
      },
      attrs: {
        "name": "sort"
      }
    }), _c('br'), _vm._v(" upload ")], 1)], 1) : _vm._e(), (_vm.pictureInfoList[index].userImgInfo[0].photoInfo.url ? true : false) ? _c('div', {
      class: {
        'odd-update': index % 2 === 0,
        'even-update': index % 2 === 1
      },
      staticStyle: {
        "position": "absolute",
        "bottom": "0px",
        "width": "19.24vw",
        "height": "19.24vw",
        "background-color": "#BBBBBB",
        "font-weight": "bold",
        "opacity": "0.7",
        "z-index": "11"
      },
      on: {
        "click": function ($event) {
          return _vm.localStorageSelect(index);
        }
      }
    }, [_c('br'), _c('van-icon', {
      staticStyle: {
        "margin-left": "7vw"
      },
      attrs: {
        "name": "sort"
      }
    }), _c('br'), _c('span', {
      staticStyle: {
        "margin-left": "2vw"
      }
    }, [_vm._v("change")])], 1) : _vm._e()], 1)]), index % 2 == 1 ? _c('div', {
      key: index,
      staticStyle: {
        "display": "flex"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-left": "25vw",
        "margin-top": "1vw",
        "margin-bottom": "1vw"
      }
    }, [_vm._v(_vm._s(`-${index}-`))]), _c('span', {
      staticStyle: {
        "margin-left": "40vw",
        "margin-top": "1vw",
        "margin-bottom": "1vw"
      }
    }, [_vm._v(_vm._s(`-${index + 1}-`))])]) : _vm._e()];
  }), _c('br'), _c('br'), _c('br'), [!_vm.uploadFlag ? _c('van-button', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw",
      "font-size": "5vw",
      "font-weight": "bold"
    },
    attrs: {
      "type": "primary",
      "size": "large",
      "color": "#D56460"
    },
    on: {
      "click": function ($event) {
        return _vm.postToShoppingCart();
      }
    }
  }, [_vm._v(" " + _vm._s("Place Order") + " ")]) : _vm._e(), _vm.uploadFlag ? _c('van-uploader', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw"
    },
    attrs: {
      "preview-image": false,
      "max-size": 26214400,
      "max-count": _vm.maxCount,
      "accept": "image/*",
      "multiple": "true",
      "after-read": _vm.afterRead
    },
    on: {
      "oversize": _vm.onOversize
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "5vw",
      "font-weight": "bold",
      "width": "100vw",
      "height": "12.52vw",
      "background-color": "#D56460",
      "border-color": "#D56460"
    },
    attrs: {
      "icon": "plus",
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Batch Upload(More " + _vm._s(_vm.remainingPictureCount) + " copies)")])], 1) : _vm._e()], _c('van-overlay', {
    attrs: {
      "show": _vm.localStorageFlag,
      "z-index": 12
    },
    on: {
      "click": function ($event) {
        return _vm.closeLocalStorageFlag();
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "142.82vw",
      "position": "fixed",
      "bottom": "0",
      "z-index": "13"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', [_c('span', {
    staticStyle: {
      "width": "100vw",
      "height": "12.82vw",
      "font-size": "20px",
      "font-weight": "bold",
      "background-color": "#FFFFFF",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v("Upload History ")]), _c('van-button', {
    staticStyle: {
      "height": "12.82vw",
      "width": "12.82vw",
      "background-color": "#FFFFFF",
      "position": "absolute",
      "right": "0",
      "top": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.closeLocalStorageFlag();
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "size": "30",
      "name": "close"
    }
  })], 1)], 1), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "132vw",
      "border": "solid 1vw #D56460",
      "box-sizing": "border-box",
      "z-index": "9999",
      "bottom": "0",
      "position": "fixed",
      "background-color": "#FFFFFF",
      "display": "flex",
      "flex-wrap": "wrap",
      "align-content": "flex-start"
    }
  }, [_vm._l(_vm.localStoragePictureUrls, function (item, index) {
    return _c('van-image', {
      key: index,
      staticStyle: {
        "width": "24vw",
        "height": "24vw",
        "margin-left": "0.5vw",
        "margin-top": "1vw"
      },
      attrs: {
        "fit": "contain",
        "src": item.src
      },
      on: {
        "click": function ($event) {
          return _vm.selectLocalStoragePicture(item);
        }
      }
    });
  }), [_c('van-uploader', {
    staticClass: "page-bottom-submit-box-single",
    staticStyle: {
      "z-index": "99",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "10vw"
    },
    attrs: {
      "max-size": 26214400,
      "accept": "image/*",
      "preview-image": false,
      "after-read": _vm.singePictureUpload
    },
    on: {
      "oversize": _vm.onOversize
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "5vw",
      "font-weight": "bold",
      "width": "100vw",
      "height": "10vw",
      "background-color": "#D56460",
      "border-color": "#D56460"
    },
    attrs: {
      "icon": "plus",
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Upload From Photo Albums")])], 1)]], 2)])]), _vm.addAddressFlag ? _c('add-address-popup', {
    attrs: {
      "add-address-popup-show": _vm.addAddressFlag
    },
    on: {
      "callbackForAdd": _vm.callbackForAdd
    }
  }) : _vm._e(), _vm.uploadProcessFlag ? _c('div', {
    staticStyle: {
      "color": "#D56460",
      "position": "fixed",
      "top": "58%",
      "left": "15%",
      "width": "70vw",
      "height": "10vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "font-size": "6vw",
      "z-index": "99999"
    }
  }, [_vm._v("Upload progress: " + _vm._s(_vm.pictureHasUploadedCount) + "/" + _vm._s(_vm.pictureArrayCount))]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };